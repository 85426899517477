<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="parseLineitemJson">
      <v-card>
        <v-card-title class="subheading">Details</v-card-title>
        <v-card-text>
          <v-col cols="4">
            <v-text-field
              v-model="vm.title"
              label="Friendly title"
              v-validate="'required'"
              data-vv-as="title"
              name="title"
              :error-messages="errors.collect('title')"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="vm.effectiveFromDate"
              label="effectiveFromDate"
              v-validate="'required'"
              data-vv-as="effectiveFromDate"
              name="effectiveFromDate"
              type="date"
              :error-messages="errors.collect('effectiveFromDate')"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="vm.effectiveToDate"
              label="effectiveToDate"
              v-validate="'required'"
              data-vv-as="effectiveToDate"
              name="effectiveToDate"
              type="date"
              :error-messages="errors.collect('effectiveToDate')"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="payload" label="Payload " required filled></v-textarea>
          </v-col>
           <v-col cols="12">
        
      </v-col>
       <v-row>
       <h5 v-if="supportCategories.length>0">Parsed Data</h5>
      </v-row>
    <v-row>
      
      
      <v-col cols="6" v-if="supportCategories.length>0"> 
        <v-select
          v-model="supportCategory"
          :items="supportCategories"
          label="Support categories"
          return-object
          @change="onCategorySelected"
        >
          <template v-slot:item="{item}">
                {{item.supportCategoryNumber}} - {{item.name}}
          </template>
          <template v-slot:selection="{item}">
                {{item.supportCategoryNumber}} - {{item.name}}
          </template>
        </v-select>
      </v-col>
       <v-col cols="6" v-if="supportCategories.length>0">
        <v-select
          v-model="registrationGroup"
          :items="registrationGroupsFiltered"
          label="Registration Groups"
          @change="onRegistrationGroupSelected"
        return-object
        >
          <template v-slot:item="{item}">
               {{item.registrationGroupNumber}} - {{item.name}}
          </template>
          <template v-slot:selection="{item}">
               {{item.registrationGroupNumber}} - {{item.name}}
          </template>
        </v-select>
      </v-col>
      </v-row>
      <v-row>
        <v-data-table
                :items="itemsFiltered"
                :dense="true"
                :items-per-page-options= "[ 50]"
                :items-per-page="50"
                :headers='[ 
                 { text: "supportCategoryNumber", value: "supportCategoryNumber" }, 
                { text: "supportCategory", value: "supportCategory" }, 
                  { text: "registrationGroupNumber", value: "registrationGroupNumber" }, 
                
                  { text: "registrationGroup", value: "registrationGroup" },                
                  { text: "supportItemNumber", value: "supportItemNumber" },
                  { text: "supportItem", value: "supportItem" },
                  { text: "supportItemDescription", value: "supportItemDescription" }
                
                ]'
                class="elevation-1 smallTextTable"
              >

               <template slot="item.supportCategoryNumber" slot-scope="props">
                  {{props.item.supportCategoryNumber}}<br/>
                 
                </template>
              <template slot="item.supportCategory" slot-scope="props">
                  {{props.item.supportCategory}}
                </template>
                <template slot="item.registrationGroupNumber" slot-scope="props">
                  {{props.item.registrationGroupNumber}}
                </template>
               
                <template slot="item.registrationGroup" slot-scope="props">
                  {{props.item.registrationGroup}}
                </template>
                <template slot="item.supportItemNumber" slot-scope="props">
                  {{props.item.supportItemNumber}}
                </template>
                <template slot="item.supportItem" slot-scope="props">
                 {{props.item.supportItem}}
                </template>
                <template slot="item.supportItemDescription" slot-scope="props">
                {{props.item.supportItemDescription}}
                </template>
               
              </v-data-table>
      </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" type="submit">Parse Json</v-btn>
          <v-btn type="button" @click="validateBeforeSubmit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
 
  </v-container>
</template>


<script>
import Vue from "vue";

// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
//const csv = require("fast-csv");
import * as lineItemService from "../../services/lineItemSevice";

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "lineItems",
  data: () => ({
    vm: {},
    payload: "",
    registrationGroup:null,
    supportCategory:null,
    registrationGroups: [],
    registrationGroupsFiltered: [],
    items:[],
    itemsFiltered: [],
    supportCategories: [],
    csvUploadPath: "/system/line-items/"
  }),

  props: {},
  mounted() {
    this.init();
  },
  computed: {},
  methods: {
    init() {},

    updateEffectiveFromDate: function(datetime) {
      this.vm.effectiveFromDate = datetime;
    },
    updateEffectiveToDate: function(datetime) {
      this.vm.effectiveToDate = datetime;
    },

    importLineItems() {},
    onCategorySelected(){
        this.registrationGroupsFiltered = this.registrationGroups.filter(x => {
          return x.supportCategoryNumber == this.supportCategory.supportCategoryNumber;
        });
    },
    onRegistrationGroupSelected(){
        this.itemsFiltered = this.items.filter(x => {
          return x.registrationGroupNumber == this.registrationGroup.registrationGroupNumber && x.supportCategoryNumber == this.supportCategory.supportCategoryNumber;
        });
    },
    buildCollections(vm, items) {
      this.registrationGroups= [];
      this.supportCategories= [];
      this.vm.supportCategories = this.supportCategories;
      this.vm.registrationGroups = this.registrationGroups;

      items.forEach(item => {
        let regGroupfound = this.registrationGroups.find(x => {
          return x.registrationGroupNumber === item.registrationGroupNumber && x.supportCategoryNumber ===item.supportCategoryNumber;
        });
        if (!regGroupfound) {
          const regGroup = {
            name: item.registrationGroup,
            supportCategoryNumber: item.supportCategoryNumber,
            registrationGroupNumber:item.registrationGroupNumber
          };
          this.registrationGroups.push(regGroup);
          regGroupfound = regGroup;
        }

        item.registrationGroupNumber = regGroupfound.registrationGroupNumber;

        var found = this.supportCategories.find(x => {
          return x.supportCategoryNumber == item.supportCategoryNumber;
        });
        if (!found) {
          this.supportCategories.push({
            name: item.supportCategory,
            supportCategoryNumber: item.supportCategoryNumber
          });
        }
      });

      vm.supportCategories = this.supportCategories;
      vm.registrationGroups = this.registrationGroups;
    },
    parseLineitemJson(){
      const me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
           this.items=[];
          this.items = JSON.parse(me.payload);

          me.buildCollections(me.vm, this.items);
        }
      });
    },
    validateBeforeSubmit() {
      const me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.items=[];
          this.items = JSON.parse(me.payload);
    
          me.buildCollections(me.vm, this.items);

          lineItemService.save(me.vm).then(ref => {
            if (ref != undefined) {
              me.vm.id = ref.id;

              this.items.forEach(item => {
                ref
                  .collection("lineItems")
                  .add(item)
                  .then(payload => {
                    console.info("item added");

                    console.log(payload);
                  })
                  .catch(error => {
                    console.info("snapshot update error");
                    console.log(error);
                  });
              });
            }
            me.$store.dispatch("setToast", {
              message: "lineItems uploaded",
              color: "success"
            });
            me.$emit("note-added", me.vm);
          });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

